<template>
  <!--
    The view for the NotesOverview-component.
    Used to display all history-comments.
  -->
  <HistoryOverview />
</template>

<script>
export default {
  name: "Notes",
  components: {
    HistoryOverview: () => import('@/components/History/HistoryOverview.vue')
  },
  metaInfo () {
    return {
      title: this.$t('notesView.notes')
    }
  }
}
</script>